import styled from "@emotion/styled";

export const StyledLabel = styled.label`
  font-weight: 400;
  display: block;
`;
export const StyledLabelBold = styled.label`
  font-weight: 600;
  display: block;
`;

export const StyledReactJsonPrint = styled.div`
  span {
    white-space: pre-wrap !important;
    word-break: break-all;
  }
  margin-bottom: 10px;
`;

// Grid System - START
export const StyledGridGeneralStyle = `
  padding: '2rem';
  background-color: #f8f9fa;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledGrid = styled.div<{ $appbarHeight?: number }>`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  height: ${({ $appbarHeight }) => `calc(100vh - ${$appbarHeight}rem)`};
`;

export const StyledGridContainer = styled.div`
  position: relative;
`;

export const StyledGridMain = styled.main<{ $appbarHeight: number; $footerHeight: number; $scrollable?: boolean }>`
  overflow-y: ${({ $scrollable }) => ($scrollable ? "scroll" : "unset")};
  height: calc(100vh - ${({ $appbarHeight, $footerHeight }) => $appbarHeight + $footerHeight + 0.5}rem);
  padding-block: 0.15rem;
  padding-inline: 0.25rem;
`;

export const StyledGridFooter = styled.footer<{ $footerHeight?: number }>`
  ${StyledGridGeneralStyle}
  bottom: 0;
  border-top: 1px solid #dee2e6;
  position: absolute;
  height: ${({ $footerHeight }) => ($footerHeight ? `${$footerHeight}rem` : "auto")};
`;
