import { fa0, fa1, fa2, fa3, fa4, fa5, fa6, fa7, fa8, fa9 } from "@fortawesome/free-solid-svg-icons";

export const backEndUrl = window.location.hostname === "localhost" ? "http://localhost:4000" : "https://backend-one-gamma-47.vercel.app";
export const sharedUrl = backEndUrl + "/api/shared/";
export const loginUrl = backEndUrl + "/api/users/login/";
export const defaultImageURL = backEndUrl + "/uploads/images/statics/empty.png";
export const defaultTimeOut = 2500;
export const statusToReload = [200, 201, 203];
export const CustomCalculatorInputValue = "0";
export const calcNumbers = [
  {
    icon: fa0,
    label: 0,
  },
  {
    icon: fa1,
    label: 1,
  },
  {
    icon: fa2,
    label: 2,
  },
  {
    icon: fa3,
    label: 3,
  },
  {
    icon: fa4,
    label: 4,
  },
  {
    icon: fa5,
    label: 5,
  },
  {
    icon: fa6,
    label: 6,
  },
  {
    icon: fa7,
    label: 7,
  },
  {
    icon: fa8,
    label: 8,
  },
  {
    icon: fa9,
    label: 9,
  },
];
export const defaultDelay = 100;
