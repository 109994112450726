import { faPenToSquare, faSort } from "@fortawesome/free-solid-svg-icons";
import { Box, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { globalContext } from "../../../GlobalContext";
import { fetchApi } from "../../../hooks/apis";
import { backEndUrl, defaultTimeOut, statusToReload } from "../../../hooks/constants";
import { getAPIs, getAlertOptions, getAllowedValues, getExtraHeaders, getFormatedDate, getGroupCount, getObjectByKey, getRootObject, getString } from "../../../hooks/functions";
import { ApplyOP, WarehouseSecions } from "../../../hooks/types";
import { menus } from "../../Navigation";
import UnitedForm from "../../forms/UnitedForm";
import UnitedTable from "../../forms/UnitedTable";
import { CustomActionButtons } from "../../shared/CustomActionButtons";
import { CustomAlert } from "../../shared/CustomAlert";
import { CustomButton } from "../../shared/CustomButton";
import CustomDrawer from "../../shared/CustomDrawer";
import { CustomTitle } from "../../shared/CustomTitle";

export default function Products(props: any) {
  // Calling Global API's DATA - START
  const { sharedData, getSharedData } = globalContext();
  // Calling Global API DATA - END

  let { sx } = props;
  const currentLocation = useLocation();
  const section = currentLocation.pathname.split("/").pop() as WarehouseSecions;

  const [alert, setAlert] = useState(getAlertOptions());
  const [reCheckData, setReCheckData] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [managedItem, setManagedItem] = useState(null);
  useEffect(() => {
    if (reCheckData) {
      getSharedData();
      setReCheckData(false);
    }
  }, [reCheckData]);

  const allowedImages = getAllowedValues(sharedData, "File", "ImageAllowedFormats")
    .map((item: any) => `image/${item.toLowerCase()}, `)
    .join(",");

  const groupCount = getGroupCount(sharedData);
  const categories = getRootObject(sharedData, "Categories");
  const tags = getRootObject(sharedData, "Tags");

  let dataSchema: any = [];
  let dataColumns: GridColDef[] = [];
  let apiUrl: string = "";
  let apiHeaders: any = {};
  dataColumns.push({ field: "namesDE", headerName: "Name DE", flex: 1, renderCell: (item: any) => item.row.names.de }, { field: "namesEN", headerName: "Name EN", flex: 1, renderCell: (item: any) => item.row.names.en }, { field: "namesAR", headerName: "Name AR", flex: 1, renderCell: (item: any) => item.row.names.ar });
  switch (section) {
    case "products":
      apiUrl = backEndUrl + getAPIs(sharedData).Products.General;
      apiHeaders = {
        genre: "PRODUCT",
        ...getExtraHeaders(false, false, true),
      };
      dataColumns.push({ field: "barcode", headerName: "Barcode", flex: 1, renderCell: (item: any) => item.row.barcode }, { field: "Price", headerName: "Price", flex: 1, renderCell: (item: any) => item.row.amounts.single.brutto.offerPrice }, { field: "Qty", headerName: "QTY", flex: 1, renderCell: (item: any) => item.row.amounts.maxQuantity });
      dataSchema = [
        { element: "CTF", value: "barcode", grid: 12, label: getString(sharedData, "barcode"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[de]", grid: 4, label: getString(sharedData, "Name DE"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[en]", grid: 4, label: getString(sharedData, "Name EN"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[ar]", grid: 4, label: getString(sharedData, "Name AR"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[tax]", grid: 3, label: getString(sharedData, "Tax"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][brutto][costPrice]", grid: 3, label: getString(sharedData, "Cost Price"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][brutto][sellPrice]", grid: 3, label: getString(sharedData, "Sell Price"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][brutto][offerPrice]", grid: 3, label: getString(sharedData, "Offer Price"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][pledge][tax]", grid: 3, label: getString(sharedData, "Pledge Tax"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][pledge][brutto]", grid: 3, label: getString(sharedData, "Pledge "), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CSL", value: "unit", grid: 3, label: getString(sharedData, "Unit"), extra: { data: getAllowedValues(sharedData, "Product", "ProductAllowedUnits"), icon: faPenToSquare, required: true } },
        { element: "CHF", value: "genre", grid: 0, extra: { defaultValue: "PRODUCT", hidden: true } },
        { element: "CTF", value: "company", grid: 3, label: getString(sharedData, "Company"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "group", grid: 3, label: getString(sharedData, "Group"), extra: { type: "number", icon: faPenToSquare, required: true, max: groupCount } },
        { element: "CFU", value: "files[0]", grid: 9, label: getString(sharedData, "Files"), extra: { icon: faPenToSquare, multiple: false, accept: allowedImages } },
        { element: "CSL", value: "categoriesIDs", grid: 12, label: getString(sharedData, "Categories"), extra: { data: categories, icon: faPenToSquare, multiple: true, checkboxes: true } },
        { element: "CSL", value: "tagsIDs", grid: 12, label: getString(sharedData, "Tags"), extra: { data: tags, icon: faPenToSquare, multiple: true, checkboxes: true } },
        { element: "CCB", value: "weightable", grid: 4, label: getString(sharedData, "Weightable"), extra: {} },
        { element: "CCB", value: "favorite", grid: 4, label: getString(sharedData, "Favorite"), extra: {} },
        { element: "CTF", value: "amounts[maxQuantity]", grid: 4, label: getString(sharedData, "Max Quantity"), extra: { icon: faPenToSquare } },
      ];
      break;
    case "tags":
      apiUrl = backEndUrl + getAPIs(sharedData).Products.General;
      apiHeaders = {
        genre: "TAG",
        ...getExtraHeaders(false, false, true),
      };
      dataColumns.push({ field: "barcode", headerName: "Barcode", flex: 1, renderCell: (item: any) => item.row.barcode }, { field: "Price", headerName: "Price", flex: 1, renderCell: (item: any) => item.row.amounts.single.brutto.offerPrice });
      dataSchema = [
        { element: "CTF", value: "barcode", grid: 12, label: getString(sharedData, "barcode"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[de]", grid: 4, label: getString(sharedData, "Name DE"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[en]", grid: 4, label: getString(sharedData, "Name EN"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[ar]", grid: 4, label: getString(sharedData, "Name AR"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[tax]", grid: 3, label: getString(sharedData, "Tax"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][brutto][costPrice]", grid: 3, label: getString(sharedData, "Cost Price"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][brutto][sellPrice]", grid: 3, label: getString(sharedData, "Sell Price"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CTF", value: "amounts[single][brutto][offerPrice]", grid: 3, label: getString(sharedData, "Offer Price"), extra: { type: "number", icon: faPenToSquare, required: true } },
        { element: "CHF", value: "genre", grid: 0, extra: { defaultValue: "TAG", hidden: true } },
        { element: "CHF", value: "unit", grid: 0, extra: { defaultValue: "UNKNOWN", hidden: false } },
        { element: "CHF", value: "weightable", grid: 0, extra: { defaultValue: "false", hidden: false } },
        { element: "CHF", value: "favorite", grid: 0, extra: { defaultValue: "false", hidden: false } },
      ];
      break;
    case "categories":
      apiUrl = backEndUrl + getAPIs(sharedData).Categories.General;
      apiHeaders = {
        ...getExtraHeaders(false, false, true),
      };
      dataColumns.push();
      dataSchema = [
        { element: "CTF", value: "names[de]", grid: 3, label: getString(sharedData, "Name DE"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[en]", grid: 3, label: getString(sharedData, "Name EN"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "names[ar]", grid: 3, label: getString(sharedData, "Name AR"), extra: { icon: faPenToSquare, required: true } },
        { element: "CTF", value: "sort", grid: 3, label: getString(sharedData, "Sort"), extra: { type: "number", icon: faSort } },
        { element: "CFU", value: "files[0]", grid: 12, label: getString(sharedData, "Files"), extra: { icon: faPenToSquare, multiple: false, accept: allowedImages } },
      ];
      break;
  }

  dataColumns.push(
    { field: "Date", headerName: "Created At", flex: 1, renderCell: (item: any) => getFormatedDate(item.row.createdAt) },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (item: any) => <CustomActionButtons onClickEdit={() => Edit(item.row)} onClickDelete={() => applyOP(item, "DELETE")} onClickDuplicate={() => applyOP(item, "DUPLICATE")} />,
    }
  );

  const Edit = (item: any) => {
    setDrawerValues(item, true);
  };

  const applyOP = async (item: any, type: ApplyOP) => {
    const settings = {
      method: type === "DELETE" ? "DELETE" : "POST",
      apiURL: apiUrl + (type === "DELETE" ? "" : "/duplicate"),
    };
    const result = await fetchApi(settings.apiURL, settings.method, { id: item.id });
    if (statusToReload.includes(result.Status)) {
      setAlert(getAlertOptions(true, result.Message, result.Details, result.Severity));
      setReCheckData(true);
      setTimeout(() => {
        setAlert(getAlertOptions());
      }, defaultTimeOut);
    }
  };

  const handleDrawerChange = (value: boolean) => {
    setDrawerValues(null, value);
  };

  const setDrawerValues = (item: any, openStatus: boolean) => {
    setManagedItem(item);
    setOpenDrawer(openStatus);
  };

  return (
    <Box sx={{ ...sx }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CustomTitle title={getObjectByKey(menus, section).primary} icon={getObjectByKey(menus, section).icon} />
        <CustomButton icon={faPenToSquare} onClick={() => setOpenDrawer(true)} />
      </Stack>
      <CustomDrawer openDrawer={openDrawer || false} closeDrawer={handleDrawerChange}>
        <UnitedForm formButtonLabel={getString(sharedData, "save")} dataSchema={dataSchema} apiUrl={apiUrl} managedItem={managedItem} />
      </CustomDrawer>
      <Grid container>
        <Grid item xs={12}>
          {alert.open && <CustomAlert title={alert.title} message={alert.message} severity={alert.severity} sx={{ marginTop: 2 }} />}
        </Grid>
      </Grid>
      <UnitedTable pageSize={15} columns={dataColumns} loading={sharedData === true} apiUrl={apiUrl} apiHeaders={apiHeaders} reCheckData={reCheckData} />
    </Box>
  );
}
