import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";

export const CCB = (props: any) => {
  let { defaultValue, label, onChange, extra } = props;
  extra.icon = extra.icon || faCircleXmark;
  extra.checkedIcon = extra.checkedIcon || faCircleCheck;

  const [checked, setChecked] = React.useState(defaultValue);
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <FormGroup sx={{ marginX: 1 }}>
      <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckChange} icon={<FontAwesomeIcon icon={extra.icon} size="sm" />} checkedIcon={<FontAwesomeIcon icon={extra.checkedIcon} size="sm" />} />} label={label} />
    </FormGroup>
  );
};
