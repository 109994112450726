import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import { globalContext } from "../../../GlobalContext";
import { getRootObject } from "../../../hooks/functions";
import { CustomSpaces } from "../CustomSpaces";

export const CFU = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { borderRadius } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { label, extra, onChange } = props;
  extra.type = extra.type || "text";
  extra.variant = extra.variant || "outlined";
  extra.multiple = extra.multiple || false;

  return (
    <>
      <TextField
        type="file"
        variant={extra.variant}
        label={label}
        onChange={onChange}
        size="small"
        required={extra.required}
        fullWidth
        inputProps={{
          style: {
            borderRadius: borderRadius,
          },
          multiple: extra.multiple,
          accept: extra.accept,
        }}
        InputProps={{
          startAdornment: extra.icon && (
            <>
              <FontAwesomeIcon icon={extra.icon} size="sm" />
              <CustomSpaces />
            </>
          ),
        }}
      />
    </>
  );
};
