import React, { ReactNode, createContext, useContext } from "react";
import { getHeaderOptions } from "./hooks/apis";
import { sharedUrl } from "./hooks/constants";
import { GlobalProps } from "./hooks/types";

interface ContextProps {
  sharedData: any;
  getSharedData: () => void;
  globalProps: GlobalProps;
  setGlobalProps: React.Dispatch<React.SetStateAction<GlobalProps>>;
}

const ApiContext = createContext<ContextProps | undefined>(undefined);

export const ApiProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sharedData, setSharedData] = React.useState<any>(null);
  const [globalProps, setGlobalProps] = React.useState<GlobalProps>(null);

  const getSharedData = async () => {
    try {
      const response = {
        Shared: await (await fetch(sharedUrl, { method: "GET", headers: getHeaderOptions() })).json(),
      };
      setSharedData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const value = { sharedData, getSharedData, globalProps, setGlobalProps };
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const globalContext = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("getContext must be used within an ApiProvider");
  }
  return context;
};
