import { Box } from "@mui/material";
import React from "react";
import { globalContext } from "../../../GlobalContext";
import { getRootObject } from "../../../hooks/functions";
import { StyledGrid } from "../../Styled";
import PossystemCategories from "./PossystemCategories";
import PossystemOrder from "./PossystemOrder";
import PossystemProducts from "./PossystemProducts";

export default function Possystem(props: any) {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { appbarHeight } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  React.useEffect(() => {}, []);

  return (
    <Box>
      <StyledGrid $appbarHeight={appbarHeight}>
        <PossystemCategories />
        <PossystemProducts />
        <PossystemOrder />
      </StyledGrid>
    </Box>
  );
}
