import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FormControl, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../../GlobalContext";
import { fetchApi } from "../../hooks/apis";
import { statusToReload } from "../../hooks/constants";
import { convertToBase64, getAlertOptions, getRightDefaultValue, getRootObject } from "../../hooks/functions";
import { CustomAlert } from "../shared/CustomAlert";
import { CustomButton } from "../shared/CustomButton";
import { CustomForm } from "../shared/CustomForm";
import { CustomImageItem } from "../shared/CustomImageItem";
import { CustomLoading } from "../shared/CustomLoading";
import { CCB } from "../shared/Inputs/CustomCheckBox";
import { CFU } from "../shared/Inputs/CustomFileUpload";
import { CHF } from "../shared/Inputs/CustomHiddenField";
import { CSL } from "../shared/Inputs/CustomSelectList";
import { CTF } from "../shared/Inputs/CustomTextField";

export default function UnitedForm(props: any) {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;

  // Calling Global API DATA - END

  let { formButtonLabel, dataSchema, apiUrl, managedItem, login } = props;
  const navigate = useNavigate();

  const method = managedItem ? "PUT" : "POST";
  dataSchema = dataSchema.map((item: any) => {
    item.defaultValue = getRightDefaultValue(item, managedItem, method);
    return item;
  });

  const state = Object.assign({}, ...dataSchema.map((item: any) => ({ [item.value]: item.defaultValue })));
  const [submitData, setSubmitData] = useState(state);
  const [alert, setAlert] = useState(getAlertOptions());

  const submitForm = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!apiUrl) return console.log("API is not defined");
    if (managedItem) submitData.id = managedItem._id;
    const result = await fetchApi(apiUrl, method, submitData);
    if (statusToReload.includes(result.Status)) {
      if (login) {
        localStorage.setItem("liekasse-token", result.Data?.Content?.token);
        navigate("categories");
      } else navigate(0);
    } else {
      setAlert(getAlertOptions(true, result.Message, result.Error, result.Severity));
    }
  };

  const updateData = (item: any, value: any) => {
    let finalValue;
    finalValue = value;
    if (item.element === "CFU") finalValue = convertToBase64(value);
    setSubmitData({ ...submitData, [item.value]: finalValue });
  };

  if (!sharedData) return <CustomLoading />;
  return (
    <CustomForm onSubmit={submitForm} noValidate={true}>
      <FormControl fullWidth>
        <Grid container alignItems={"center"} justifyContent={"center"} spacing={spacing.xl}>
          {dataSchema.map(
            (item: any, index: number) =>
              !item.extra.hidden && (
                <Grid key={index} item xs={item.grid}>
                  {item.element === "CTF" && <CTF {...item} onChange={(e: any) => updateData(item, e.target.value)} />}
                  {item.element === "CFU" && <CFU {...item} onChange={(e: any) => updateData(item, e.target.files)} />}
                  {item.element === "CCB" && <CCB {...item} onChange={(e: any) => updateData(item, e)} />}
                  {item.element === "CSL" && <CSL {...item} onChange={(e: any) => updateData(item, e)} />}
                  {item.element === "CHF" && <CHF {...item} onChange={(e: any) => updateData(item, e)} />}
                </Grid>
              )
          )}
          {managedItem && managedItem.filesIDs.length > 0 && (
            <Grid item xs={12}>
              <CustomImageItem height={150} item={managedItem} />
            </Grid>
          )}
          <Grid item xs={12}>
            {alert.open && <CustomAlert title={alert.title} message={alert.message} severity={alert.severity} />}
          </Grid>
          <Grid item xs={12}>
            <CustomButton icon={faPlusCircle} fullWidth={true} label={formButtonLabel} />
          </Grid>
        </Grid>
      </FormControl>
    </CustomForm>
  );
}
