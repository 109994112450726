import { Grid } from "@mui/material";
import React from "react";
import { globalContext } from "../../../GlobalContext";
import { fetchApi } from "../../../hooks/apis";
import { backEndUrl } from "../../../hooks/constants";
import { getAPIs, getExtraHeaders, getRootObject, getString } from "../../../hooks/functions";
import { StyledGridContainer, StyledGridFooter, StyledGridMain } from "../../Styled";
import { CustomItem } from "../../shared/CustomItem";
import { CustomLoading } from "../../shared/CustomLoading";
import { CustomPagination } from "../../shared/CustomPagination";

export default function PossystemCategories(props: any) {
  // Calling Global API's DATA - START
  const { sharedData, globalProps, setGlobalProps } = globalContext();
  const { appbarHeight, footerHeight, spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  const [categories, setCategories] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<any>({ paginationcurrent: 1, paginationlimit: 10, paginationtotal: 0 });

  const categoriesAPI = backEndUrl + getAPIs(sharedData).Categories.General;

  const handleWarehouseCategories = (value: string) => {
    setGlobalProps({
      ...globalProps,
      Possystem: {
        ...globalProps?.Possystem,
        Categories: {
          id: value,
        },
      },
    });
  };

  const loadCategories = React.useCallback(async () => {
    setLoading(true);
    const data = await fetchApi(categoriesAPI, "GET", {}, false, getExtraHeaders(false, false, true, pagination.paginationcurrent, pagination.paginationlimit));
    setPagination((prevPagination: any) => ({ ...prevPagination, paginationtotal: data.Details.total }));
    setCategories(data.Data.Content);
    setLoading(false);
  }, [pagination.paginationcurrent]);

  React.useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const favItem = {
    filesIDs: [],
    icon: "FAVORITE",
    names: { de: getString(sharedData, "favorites") },
  };

  if (loading) return <CustomLoading />;
  return (
    <StyledGridContainer>
      <StyledGridMain $scrollable $appbarHeight={appbarHeight} $footerHeight={footerHeight}>
        <Grid container spacing={spacing.medium}>
          <Grid item xs={12}>
            <CustomItem item={favItem} onClick={() => handleWarehouseCategories("0")} />
          </Grid>
          {categories?.map((item: any, index: number) => (
            <Grid key={index} item xs={6}>
              <CustomItem item={item} onClick={() => handleWarehouseCategories(item._id)} />
            </Grid>
          ))}
        </Grid>
      </StyledGridMain>
      <StyledGridFooter $footerHeight={footerHeight}>
        <CustomPagination pagination={pagination} onClick={(value: number) => setPagination({ ...pagination, paginationcurrent: value })} />
      </StyledGridFooter>
    </StyledGridContainer>
  );
}
