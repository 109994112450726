import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AppBar, Card, CardContent, Divider, Drawer, List, ListItem, ListItemText, Toolbar } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { globalContext } from "../GlobalContext";
import { getRootObject } from "../hooks/functions";
import { Navigation } from "./Navigation";
import { StyledLabel, StyledLabelBold } from "./Styled";
import { CustomButton } from "./shared/CustomButton";
import { CustomChip } from "./shared/CustomChip";

export const Header = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { appbarHeight } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
    setOpenDrawer(false);
  }, [currentLocation]);

  return (
    <>
      <AppBar elevation={0} position="static" color="primary" sx={{ color: "white", height: `${appbarHeight}rem`, justifyContent: "center" }}>
        <Toolbar variant="dense">
          <CustomButton onClick={() => setOpenDrawer(!openDrawer)} label={"Liekasse"} icon={faBars} variant="text" />
        </Toolbar>
      </AppBar>
      <Drawer variant="temporary" anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)} elevation={0}>
        <Card sx={{ margin: 1 }}>
          <CardContent>
            <StyledLabelBold>SellPoint</StyledLabelBold>
            <StyledLabel>Username</StyledLabel>
          </CardContent>
        </Card>
        <Divider sx={{ marginY: 1 }} />
        <Navigation />
        <List style={{ marginTop: "auto" }}>
          <Divider sx={{ marginY: 1 }} />
          <ListItem sx={{ textAlign: "center" }}>
            <ListItemText>
              <CustomChip label="Liekasse © V1.0" size="small" color="primary" />
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
