import { CustomChip } from "../CustomChip";

export const CHF = (props: any) => {
  let { extra } = props;
  extra.defaultValue = extra.defaultValue || "";

  return (
    <>
      <CustomChip label={extra.defaultValue} sx={{ width: "100%" }} color="primary" />
    </>
  );
};
