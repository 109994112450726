import { faComputer, faKey, faLayerGroup, faShapes, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../GlobalContext";
import { getRootObject, isAllowed } from "../hooks/functions";
import Login from "./pages/account/Login";
import Error404 from "./pages/others/404";
import Possystem from "./pages/possystem/Possystem";
import Warehouse from "./pages/warehouse/Warehouse";

export const menus = [
  { key: "all", primary: "Home", to: "*", icon: faKey, roles: ["A1", "B1"], visibleMenu: false, element: <Error404 sx={{ margin: 2 }} /> },
  { key: "login", primary: "Login", to: "/", icon: faKey, roles: ["PUBLIC"], visibleMenu: true, element: <Login sx={{ margin: 2 }} /> },
  { key: "categories", primary: "Categories", to: "/categories", icon: faShapes, roles: ["A1", "B1"], visibleMenu: true, element: <Warehouse sx={{ margin: 2 }} /> },
  { key: "tags", primary: "Tags", to: "/tags", icon: faTag, roles: ["A1", "B1"], visibleMenu: true, element: <Warehouse sx={{ margin: 2 }} /> },
  { key: "products", primary: "Products", to: "/products", icon: faLayerGroup, roles: ["A1", "B1"], visibleMenu: true, element: <Warehouse sx={{ margin: 2 }} /> },
  { key: "possystem", primary: "POS System", to: "/possystem", icon: faComputer, roles: ["A1", "B1"], visibleMenu: true, element: <Possystem /> },
  { key: "possystemID", primary: "POS System", to: "/possystem/:id", icon: faComputer, roles: ["A1", "B1"], visibleMenu: false, element: <Possystem /> },
];

export const Navigation = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { drawerWidth } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  const navigate = useNavigate();

  return (
    <List sx={{ width: drawerWidth }}>
      {menus.map(
        (item, index) =>
          isAllowed(sharedData, item, "Navigation") && (
            <ListItem key={item.key} onClick={() => navigate(item.to)}>
              <ListItemButton>
                <ListItemIcon>
                  <FontAwesomeIcon icon={item.icon} />
                </ListItemIcon>
                <ListItemText primary={item.primary} />
              </ListItemButton>
            </ListItem>
          )
      )}
    </List>
  );
};
