export function getHeaderOptions(isFormData: boolean = false, extraHeaders: any = {}) {
  const getTokenFromLocalStorage = localStorage.getItem("liekasse-token");
  const headers = {
    ...extraHeaders,
    "Content-Type": isFormData ? "multipart/form-data" : "application/x-www-form-urlencoded",
    ...(getTokenFromLocalStorage && { Authorization: `Bearer ${getTokenFromLocalStorage}` }),
  };
  return headers;
}

export async function fetchApi(url: RequestInfo | URL, method: any, body: any, isFormData: boolean = false, extraHeaders: any = {}) {
  const data = isFormData ? new FormData() : new URLSearchParams();
  Object.keys(body).forEach((key) => {
    Array.isArray(body[key])
      ? body[key].forEach((item: any) => {
          data.append(key, item);
        })
      : data.append(key, body[key]);
  });
  const options = {
    method: method,
    headers: {
      ...extraHeaders,
      ...getHeaderOptions(isFormData),
    },
    ...(method !== "GET" && { body: data }),
  };
  return await fetch(url, options).then((res) => res.json());
}
