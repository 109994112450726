import { Box, ThemeProvider, createTheme } from "@mui/material";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { globalContext } from "./GlobalContext";
import { Header } from "./components/Header";
import { menus } from "./components/Navigation";
import { CustomLoading } from "./components/shared/CustomLoading";
import { CustomCalculatorInputValue } from "./hooks/constants";
import { getRootObject, isAllowed } from "./hooks/functions";

export default function App() {
  // Calling Global API's DATA - START
  const { sharedData, getSharedData, setGlobalProps } = globalContext();
  const theme = createTheme({ palette: getRootObject(sharedData, "Public")?.Layout?.Theme });
  // Calling Global API DATA - END

  const currentLocation = useLocation();
  useEffect(() => {
    getSharedData();
    // Reset Context API's Data
    setGlobalProps({ CustomCalculator: { inputValue: CustomCalculatorInputValue } });
  }, [currentLocation]);
  // Calling Global API DATA - END

  if (!sharedData) return <CustomLoading />;
  return (
    <>
      <ThemeProvider theme={theme}>
        <SpeedInsights />
        <Header />
        <Box>
          <Routes key={currentLocation.pathname}>{menus.map((item) => isAllowed(sharedData, item, "App") && <Route key={item.key} path={item.to} element={item.element} />)}</Routes>
        </Box>
      </ThemeProvider>
    </>
  );
}
