import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Drawer, Grid, Stack } from "@mui/material";
import { CustomButton } from "./CustomButton";

export default function CustomDrawer({ children, ...props }: any) {
  let { openDrawer, closeDrawer, anchor, sx } = props;
  anchor = anchor || "bottom";

  return (
    <>
      <Drawer open={openDrawer} onClose={() => closeDrawer(false)} sx={{ ...sx }} anchor={anchor}>
        <Stack sx={{ padding: 2 }}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <CustomButton icon={faCircleXmark} onClick={() => closeDrawer(false)} sx={{ marginBottom: 2 }} />
          </Grid>
          {children}
        </Stack>
      </Drawer>
    </>
  );
}
