import React from "react";

export const CustomSpaces = (props: any) => {
  let { spaces } = props;
  spaces = spaces || 2;

  return (
    <>
      {Array.from(Array(spaces).keys()).map((item, index) => (
        <React.Fragment key={index}>&nbsp;</React.Fragment>
      ))}
    </>
  );
};
