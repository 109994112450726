import { Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { globalContext } from "../../../GlobalContext";
import { fetchApi } from "../../../hooks/apis";
import { backEndUrl } from "../../../hooks/constants";
import { getAPIs, getExtraHeaders, getRootObject } from "../../../hooks/functions";
import { StyledGridContainer, StyledGridFooter, StyledGridMain } from "../../Styled";
import { CustomAlert } from "../../shared/CustomAlert";
import { CustomItem } from "../../shared/CustomItem";
import { CustomLoading } from "../../shared/CustomLoading";
import { CustomPagination } from "../../shared/CustomPagination";
import PossystemProductsHeader from "./PossystemProductsHeader";

export default function PossystemProducts(props: any) {
  // Calling Global API's DATA - START
  const { sharedData, globalProps, setGlobalProps } = globalContext();
  const { appbarHeight, footerHeight, spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const currentLocationOrderId = currentLocation.pathname.split("/")[2];

  const [products, setProducts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<any>({ paginationcurrent: 1, paginationlimit: 6, paginationtotal: 0 });

  const productsAPI = backEndUrl + getAPIs(sharedData).Products.General;
  const ordersAPI = backEndUrl + getAPIs(sharedData).Orders.General;

  const addProductToOrder = async (item: any) => {
    return await fetchApi(ordersAPI + "/addProductToOrder", "PUT", item, false);
  };

  const handleWarehouseProducts = async (value: string, sperated: boolean = false) => {
    if (currentLocationOrderId === undefined || currentLocationOrderId === "") {
      await fetchApi(ordersAPI, "POST", {}, false)
        .then((data) => addProductToOrder({ id: data.Data.Content._id, productId: value, sperated }))
        .then((data) => navigate(`/possystem/${data.Data.Content._id}`));
    } else {
      addProductToOrder({ id: currentLocationOrderId, productId: value, sperated });
    }
    setGlobalProps({
      ...globalProps,
      Possystem: {
        ...globalProps?.Possystem,
        Order: moment().format("YYYYMMDDHHmmssSSS"),
      },
    });
  };

  const loadProducts = React.useCallback(
    async (category: string) => {
      setLoading(true);
      const selector = category === "0" ? { favorite: true } : { category };
      const data = await fetchApi(productsAPI, "GET", {}, false, { ...selector, genre: "PRODUCT", ...getExtraHeaders(false, false, true, pagination.paginationcurrent, pagination.paginationlimit) });
      setPagination({ ...pagination, paginationtotal: data.Data.Content.length === 0 ? 0 : data.Details.total });
      setProducts(data.Data.Content);
      setLoading(false);
    },
    [pagination.paginationcurrent]
  );

  React.useEffect(() => {
    loadProducts(globalProps?.Possystem?.Categories?.id || "0");
  }, [globalProps?.Possystem?.Categories?.id, loadProducts]);

  const PossystemProductsMain = () => {
    return products?.length === 0 ? (
      <CustomAlert title="No products found" message="Please choose another Category" severity="info" />
    ) : (
      <Grid container spacing={spacing.medium}>
        {products?.map((item: any, index: number) => (
          <Grid key={index} item xs={4}>
            <CustomItem item={item} onClick={() => handleWarehouseProducts(item._id)} OnClickForSperated={() => handleWarehouseProducts(item._id, true)} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <StyledGridContainer>
      <StyledGridMain $scrollable $appbarHeight={appbarHeight} $footerHeight={footerHeight}>
        <PossystemProductsHeader />
        {loading ? <CustomLoading /> : <>{PossystemProductsMain()}</>}
      </StyledGridMain>
      <StyledGridFooter $footerHeight={footerHeight}>
        <CustomPagination pagination={pagination} onClick={(value: number) => setPagination({ ...pagination, paginationcurrent: value })} />
      </StyledGridFooter>
    </StyledGridContainer>
  );
}
