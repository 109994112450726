import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "@mui/material";
import ReactJsonPrint from "react-json-print";
import { StyledReactJsonPrint } from "../Styled";
import { CustomTitle } from "./CustomTitle";

export const CustomJSONPreview = (props: any) => {
  let { data, title, expanded, transparent } = props;

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{
          ...(!transparent && { backgroundColor: "rgba(0, 0, 0, 0.05)" }),
          padding: 2,
          marginBottom: 2,
          maxheight: 50,
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <StyledReactJsonPrint>
          <ReactJsonPrint expanded={expanded || false} dataObject={data} />
        </StyledReactJsonPrint>
        {title && <CustomTitle variant="span" title={title.toUpperCase()} icon={faFileAlt} />}
      </Stack>
    </>
  );
};
