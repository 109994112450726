import { faBarcode, faMoneyBill1Wave, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Grid, Stack } from "@mui/material";
import { globalContext } from "../../../GlobalContext";
import { getRootObject, getString } from "../../../hooks/functions";
import { CustomButton } from "../../shared/CustomButton";
import { CustomCalculator } from "../../shared/CustomCalculator";
import { CustomChip } from "../../shared/CustomChip";
import { CustomStickyHeader } from "../../shared/CustomStickyHeader";
import { CCB } from "../../shared/Inputs/CustomCheckBox";
import { CTF } from "../../shared/Inputs/CustomTextField";

export default function PossystemProductsHeader(props: any) {
  // Calling Global API's DATA - START
  const { sharedData, globalProps } = globalContext();
  const { borderRadius, spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  return (
    <CustomStickyHeader>
      <Grid container spacing={spacing.medium} sx={{ marginBottom: spacing.medium }}>
        <Grid item xs={5}>
          <CTF extra={{ placeholder: getString(sharedData, "search"), icon: faSearch }} />
        </Grid>
        <Grid item xs={5}>
          <CTF extra={{ placeholder: getString(sharedData, "barcode"), icon: faBarcode }} />
        </Grid>
        <Grid item xs={2}>
          <CustomChip label={globalProps?.CustomCalculator?.inputValue} sx={{ width: "100%", height: "2.5rem", fontSize: "1rem", padding: 0.5, borderRadius: borderRadius }} />
        </Grid>
        <Grid item xs={12}>
          <CTF extra={{ placeholder: getString(sharedData, "givedmoney"), icon: faMoneyBill1Wave }} />
        </Grid>
        <Grid item xs={6} alignContent={"space-between"}>
          <Stack direction="column" spacing={spacing.medium}>
            <CustomButton sx={{ justifyContent: "flex-start" }} label={getString(sharedData, "cancelinvoice")} color="error" onClick={() => console.log("Clicked")} />
            <CustomButton sx={{ justifyContent: "flex-start" }} label={getString(sharedData, "ecinvoice")} color="info" onClick={() => console.log("Clicked")} />
            <CustomButton sx={{ justifyContent: "flex-start" }} label={getString(sharedData, "cashinvoice")} color="success" onClick={() => console.log("Clicked")} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" sx={{ marginTop: spacing.medium }}>
            <CCB label={getString(sharedData, "print")} onChange={() => console.log("Clicked")} extra={{}} defaultValue={false} />
            <CCB label={getString(sharedData, "separatpay")} onChange={() => console.log("Clicked")} extra={{}} defaultValue={false} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <CustomCalculator />
        </Grid>
      </Grid>
    </CustomStickyHeader>
  );
}
