import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { globalContext } from "../../../GlobalContext";
import { getRightKey, getRightLabel, getRootObject, replaceIDwithLabel } from "../../../hooks/functions";
import { CustomChip } from "../CustomChip";
import { CustomSpaces } from "../CustomSpaces";

export const CSL = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { borderRadius } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { defaultValue, label, extra, onChange } = props;
  extra.checkboxes = extra.checkboxes || false;
  extra.type = extra.type || "text";
  extra.variant = extra.variant || "outlined";
  extra.data = extra.data || [];
  extra.multiple = extra.multiple || false;

  const initSelectedIDs = () => {
    return typeof defaultValue == "string" ? [defaultValue] : defaultValue.map((item: any) => getRightKey(item));
  };

  const [selectedIDs, setSelectedIDs] = React.useState<any[]>(initSelectedIDs());
  const handleChange = (event: SelectChangeEvent<typeof selectedIDs>) => {
    const {
      target: { value },
    } = event;
    const newSelectedIDs = typeof value == "string" ? value.split(",") : value;
    setSelectedIDs(newSelectedIDs);
    onChange(newSelectedIDs);
  };

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel>{label}</InputLabel>
        <Select
          multiple={extra.multiple}
          value={selectedIDs}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          sx={{ borderRadius: borderRadius }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {selected.map((selectedItem) => {
                return <CustomChip key={getRightKey(selectedItem)} label={replaceIDwithLabel(extra.data, selectedItem)} size="small" />;
              })}
            </Box>
          )}
        >
          {extra.data.map((item: any) => (
            <MenuItem key={getRightKey(item)} value={getRightKey(item)} sx={{ padding: 2 }}>
              {extra.checkboxes ? (
                <Checkbox checked={selectedIDs.indexOf(getRightKey(item)) > -1} />
              ) : (
                <>
                  <FontAwesomeIcon icon={faDotCircle} size="xs" />
                  <CustomSpaces />
                </>
              )}
              <ListItemText primary={getRightLabel(item)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
