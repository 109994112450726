import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { fetchApi } from "../../hooks/apis";
import { statusToReload } from "../../hooks/constants";

export default function UnitedTable(props: any) {
  const { pageSize, loading, columns, apiUrl, apiHeaders, reCheckData } = props;
  const [rows, setRows] = useState<any[]>([]);

  const getData = async () => {
    if (!apiUrl) return console.log("API is not defined");
    const result = await fetchApi(apiUrl, "GET", {}, false, {
      ...apiHeaders,
    });
    if (statusToReload.includes(result.Status)) setRows(result.Data.Content);
  };
  React.useEffect(() => {
    getData();
  }, [reCheckData]);

  return (
    <>
      <DataGrid
        sx={{ marginY: 2 }}
        getRowId={(row) => row._id}
        rows={rows}
        columns={columns}
        loading={loading}
        rowSelection={false}
        pageSizeOptions={[5, 15, 25, 50, 100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSize,
            },
          },
        }}
      />
    </>
  );
}
