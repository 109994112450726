import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import { globalContext } from "../../../GlobalContext";
import { getRootObject } from "../../../hooks/functions";
import { CustomButtonIcon } from "../CustomButtonIcon";
import { CustomSpaces } from "../CustomSpaces";

export const CTF = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { borderRadius } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { defaultValue, label, extra, onChange, onClick } = props;
  extra.type = extra.type || "text";
  extra.variant = extra.variant || "outlined";

  return (
    <>
      <TextField
        type={extra.type}
        variant={extra.variant}
        label={label}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={!onChange && true}
        {...(extra.placeholder && { placeholder: extra.placeholder })}
        size="small"
        required={extra.required}
        fullWidth
        inputProps={{
          style: {
            borderRadius: borderRadius,
          },
        }}
        InputProps={{
          ...(extra.type === "number" ? { inputProps: { min: extra.min || 0, max: extra.max || 100 } } : {}),
          startAdornment: extra.icon && (
            <>
              <FontAwesomeIcon icon={extra.icon} size="sm" color="silver" />
              <CustomSpaces />
            </>
          ),
          endAdornment: extra.iconEnd && (
            <>
              <CustomSpaces />
              <CustomButtonIcon icon={extra.iconEnd} onClick={onClick && onClick} disabled={onClick == null ? true : false} />
            </>
          ),
        }}
      />
    </>
  );
};
