import { faCar } from "@fortawesome/free-solid-svg-icons";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { globalContext } from "../../../GlobalContext";
import { fetchApi } from "../../../hooks/apis";
import { CustomCalculatorInputValue, backEndUrl } from "../../../hooks/constants";
import { delayedExecution, getAPIs, getExtraHeaders, getRootObject, isOrderNotValid, isToFixed } from "../../../hooks/functions";
import { StyledGridContainer, StyledGridFooter, StyledGridMain } from "../../Styled";
import { CustomButtonIcon } from "../../shared/CustomButtonIcon";
import { CustomChip } from "../../shared/CustomChip";
import { CustomLoading } from "../../shared/CustomLoading";
import { CustomOrderdetail } from "../../shared/CustomOrderdetail";
import { CustomStickyHeader } from "../../shared/CustomStickyHeader";

export default function PossystemOrder(props: any) {
  // Calling Global API's DATA - START
  const { sharedData, globalProps, setGlobalProps } = globalContext();
  const { appbarHeight, footerHeight, spacing, currency } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const currentLocationOrderId = currentLocation.pathname.split("/")[2];

  const [order, setOrder] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const ordersAPI = backEndUrl + getAPIs(sharedData).Orders.General;
  const loadOrder = async () => {
    setLoading(true);
    setGlobalProps({ CustomCalculator: { inputValue: CustomCalculatorInputValue } });
    setOrder({});
    const data = await fetchApi(ordersAPI, "GET", {}, false, { id: currentLocationOrderId, ...getExtraHeaders(false, false, true) });
    isOrderNotValid(data) && navigate("/possystem");
    setOrder(data.Data.Content[0]);
    setLoading(false);
  };

  const cancelProductFromOrder = async (item: any) => {
    await fetchApi(ordersAPI + "/cancelProductFromOrder", "PUT", { id: currentLocationOrderId, orderDetailId: item._id }, false, {});
    loadOrder();
  };

  const updateProductQuantityInOrder = async (item: any) => {
    await fetchApi(ordersAPI + "/updateProductQuantityInOrder", "PUT", { id: currentLocationOrderId, orderDetailId: item._id, value: globalProps?.CustomCalculator?.inputValue }, false);
    loadOrder();
  };

  React.useEffect(() => {
    if (!currentLocationOrderId) return;
    delayedExecution(loadOrder);
  }, [currentLocationOrderId, globalProps?.Possystem?.Order]);

  const amounts = {
    brutto: order?.amounts ? order.amounts.brutto.offerPrice : 0,
    netto: order?.amounts ? order.amounts.netto.offerPrice : 0,
    vat: order?.amounts ? order.amounts.vat.offerPrice : 0,
    pledge: order?.amounts ? order.amounts.pledge.brutto : 0,
  };

  if (loading) return <CustomLoading />;
  return (
    <StyledGridContainer>
      <StyledGridMain $scrollable $appbarHeight={appbarHeight} $footerHeight={footerHeight}>
        <CustomStickyHeader>
          <Stack direction="row" spacing={spacing.xl} justifyContent="space-between" flexWrap="wrap" useFlexGap>
            <CustomChip label={`${isToFixed(amounts.brutto)} ${currency.symbol}`} sx={{ width: "100%", fontSize: "1.5rem" }} color={"primary"} />
            <CustomChip label={`${isToFixed(amounts.netto)} ${currency.symbol}`} />
            <CustomChip label={`${isToFixed(amounts.vat)} ${currency.symbol}`} />
            <CustomChip label={`${isToFixed(amounts.pledge)} ${currency.symbol}`} />
          </Stack>
        </CustomStickyHeader>
        <Grid container spacing={spacing.medium}>
          {order?.orderDetails?.map((item: any, index: number) => (
            <Grid key={index} item xs={12}>
              {<CustomOrderdetail item={item} cancelProductFromOrder={() => cancelProductFromOrder(item)} updateProductQuantityInOrder={() => updateProductQuantityInOrder(item)} />}
            </Grid>
          ))}
        </Grid>
      </StyledGridMain>
      <StyledGridFooter $footerHeight={footerHeight}>
        <Stack direction="row" justifyContent="space-evenly">
          <CustomButtonIcon icon={faCar} iconSize={"xs"} onClick={() => console.log("Clicked")} />
          <CustomButtonIcon icon={faCar} iconSize={"xs"} onClick={() => console.log("Clicked")} />
        </Stack>
      </StyledGridFooter>
    </StyledGridContainer>
  );
}
