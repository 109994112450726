import { faCalculator, faClose, faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { Box, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { globalContext } from "../../GlobalContext";
import { getRootObject, getString, isToFixed } from "../../hooks/functions";
import { CustomButtonIcon } from "./CustomButtonIcon";
import { CustomChip } from "./CustomChip";
import { CCB } from "./Inputs/CustomCheckBox";
import { CTF } from "./Inputs/CustomTextField";

export const CustomOrderdetail = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { borderRadius, spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { cancelProductFromOrder, updateProductQuantityInOrder, item } = props;

  return (
    <>
      <Card sx={{ display: "flex", flexDirection: "column", height: "100%", borderRadius: borderRadius }} elevation={1}>
        <Box sx={{ height: "100%", padding: spacing.medium }}>
          <CardActions sx={{ margin: 0, padding: 0, justifyContent: "space-between" }}>
            <CustomButtonIcon onClick={cancelProductFromOrder} icon={faClose} disabled={item.status !== "ADDED"} />
            <CustomChip label={item.status} color={item.status === "ADDED" ? "info" : "error"} />
          </CardActions>
          <CardContent sx={{ margin: 0, padding: 0 }}>
            <Typography variant="h5" sx={{ textAlign: "end", fontWeight: "medium" }}>
              {isToFixed(item.amounts.single.brutto.offerPrice)} €
            </Typography>
            <CCB label={item.names.de} onChange={() => console.log("Clicked")} extra={{}} defaultValue={false} />
            <Stack direction="row" spacing={spacing.medium} sx={{ marginY: spacing.medium }}>
              <CTF defaultValue={item.amounts.selectedQuantity} onClick={item.status === "ADDED" ? updateProductQuantityInOrder : null} extra={{ placeholder: getString(sharedData, "quantity"), icon: faMoneyBill1Wave, iconEnd: faCalculator }} />
            </Stack>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};
