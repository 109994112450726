import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "@mui/material";
import { CustomButtonIcon } from "./CustomButtonIcon";

export const CustomActionButtons = (props: any) => {
  let { onClickEdit, onClickDelete, onClickDuplicate } = props;

  return (
    <Stack direction="row">
      {onClickEdit && <CustomButtonIcon icon={faPenToSquare} variant="contained" onClick={onClickEdit} />}
      {onClickDelete && <CustomButtonIcon icon={faTrashCan} variant="contained" onClick={onClickDelete} />}
      {onClickDuplicate && <CustomButtonIcon icon={faCopy} variant="contained" onClick={onClickDuplicate} />}
    </Stack>
  );
};
