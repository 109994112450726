import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import { globalContext } from "../../GlobalContext";
import { getRootObject } from "../../hooks/functions";
import { CustomSpaces } from "./CustomSpaces";

export const CustomButton = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { borderRadius } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { label, icon, iconSize, onClick, variant, fullWidth, color, sx, disabled } = props;

  return (
    <>
      <Button onClick={onClick} color={color || "primary"} sx={{ color: "white", ...sx, height: "2rem", borderRadius: borderRadius }} variant={variant || "contained"} type={onClick ? "button" : "submit"} fullWidth={fullWidth || false} disableElevation disabled={disabled || false}>
        {icon && <FontAwesomeIcon size={iconSize || "lg"} icon={icon} />}
        {icon && label && <CustomSpaces />}
        {label && <Typography sx={{ fontWeight: sx?.fontWeight || "regular" }}>{label}</Typography>}
      </Button>
    </>
  );
};
