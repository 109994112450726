import { Chip } from "@mui/material";
import { globalContext } from "../../GlobalContext";
import { getRootObject } from "../../hooks/functions";

export const CustomChip = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { borderRadius } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { sx } = props;

  return <Chip {...props} sx={{ ...sx, borderRadius }} />;
};
