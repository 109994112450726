import { Box } from "@mui/material";
import { globalContext } from "../../GlobalContext";
import { getRootObject } from "../../hooks/functions";

export const CustomStickyHeader = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { children } = props;

  return <Box sx={{ backgroundColor: "white", position: "sticky", top: 0, zIndex: 1, padding: spacing.medium, marginBottom: spacing.medium, boxShadow: 1 }}>{children}</Box>;
};
