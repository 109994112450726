import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";

export const CustomButtonIcon = (props: any) => {
  let { icon, iconSize, onClick, color, disabled } = props;

  return (
    <>
      <IconButton onClick={onClick} color={color || "primary"} sx={{ padding: 1.5 }} type={onClick ? "button" : "submit"} disabled={disabled || false}>
        <FontAwesomeIcon size={iconSize || "xs"} icon={icon} />
      </IconButton>
    </>
  );
};
