import { Stack } from "@mui/material";
import { globalContext } from "../../GlobalContext";
import { getRootObject } from "../../hooks/functions";
import { CustomChip } from "./CustomChip";

export const CustomPagination = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { pagination, onClick } = props;
  let { paginationcurrent, paginationlimit, paginationtotal } = pagination;

  return (
    <Stack direction="row" spacing={spacing.small}>
      {Array.from(Array(Math.ceil(paginationtotal / paginationlimit)).keys()).map((item, index) => (
        <CustomChip key={index} label={item + 1} onClick={() => onClick(item + 1)} color={item + 1 === paginationcurrent ? "primary" : "default"} />
      ))}
    </Stack>
  );
};
