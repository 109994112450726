import { Alert, AlertTitle } from "@mui/material";
import { StyledReactJsonPrint } from "../Styled";
import { CustomJSONPreview } from "./CustomJSONPreview";

export const CustomAlert = (props: any) => {
  let { title, message, severity, sx } = props;

  const renderMessageContent = () => {
    if (Array.isArray(message)) {
      return (
        <StyledReactJsonPrint>
          <CustomJSONPreview transparent expanded data={message} />
        </StyledReactJsonPrint>
      );
    } else if (typeof message === "object") {
      return (
        <StyledReactJsonPrint>
          <CustomJSONPreview transparent expanded data={message} />
        </StyledReactJsonPrint>
      );
    } else {
      return message;
    }
  };

  return (
    <Alert severity={severity} sx={{ width: "auto", ...sx }}>
      <AlertTitle>{title}</AlertTitle>
      {renderMessageContent()}
    </Alert>
  );
};
