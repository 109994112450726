import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Box, Card, CardActions, CardContent } from "@mui/material";
import { globalContext } from "../../GlobalContext";
import { getRootObject } from "../../hooks/functions";
import { CustomButton } from "./CustomButton";
import { CustomImageItem } from "./CustomImageItem";

export const CustomItem = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  const { borderRadius } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { onClick, OnClickForSperated, item } = props;

  return (
    <>
      <Card sx={{ display: "flex", flexDirection: "column", height: "100%", borderRadius: borderRadius }} elevation={1}>
        <Box onClick={onClick && onClick} sx={{ cursor: "pointer", height: "100%", padding: 0.5, "&:hover": { backgroundColor: "rgb(0,0,0,0.1)" } }}>
          <CustomImageItem item={item} height={50} />
          <CardContent sx={{ padding: 0, paddingTop: 0.5, textAlign: "center" }}>{item.names.de}</CardContent>
        </Box>
        {OnClickForSperated && (
          <CardActions sx={{ mt: "auto", padding: 0 }}>
            <CustomButton onClick={OnClickForSperated && OnClickForSperated} icon={faPlusCircle} sx={{ width: "100%" }} />
          </CardActions>
        )}
      </Card>
    </>
  );
};
