import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Box, Stack } from "@mui/material";
import { globalContext } from "../../../GlobalContext";
import { loginUrl } from "../../../hooks/constants";
import { getObjectByKey, getString } from "../../../hooks/functions";
import { menus } from "../../Navigation";
import UnitedForm from "../../forms/UnitedForm";
import { CustomTitle } from "../../shared/CustomTitle";

export default function Login(props: any) {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  // Calling Global API DATA - END

  let { sx } = props;

  const dataSchema = [
    { element: "CTF", value: "email", grid: 6, label: getString(sharedData, "useremail"), extra: { type: "email", icon: faPenToSquare, required: true } },
    { element: "CTF", value: "password", grid: 6, label: getString(sharedData, "userpassword"), extra: { type: "password", icon: faPenToSquare, required: true } },
  ];

  return (
    <Box sx={{ ...sx }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CustomTitle title={getObjectByKey(menus, "login").primary} icon={getObjectByKey(menus, "login").icon} />
      </Stack>
      <UnitedForm formButtonLabel={getString(sharedData, "login")} dataSchema={dataSchema} apiUrl={loginUrl} login={true} />
    </Box>
  );
}
