import { Box } from "@mui/material";
import { CustomAlert } from "../../shared/CustomAlert";

export default function Error404(props: any) {
  let { sx } = props;

  return (
    <Box sx={{ ...sx }}>
      <CustomAlert severity="error" title="404 - Page Not Found" message="The page you are looking for does not exist." />
    </Box>
  );
}
