import { faImages, faStar } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { defaultDelay } from "./constants";

export function isMobile() {
  const theme = useTheme();
  const isSize = useMediaQuery(theme.breakpoints.down("sm"));
  return isSize;
}
export function isTablet() {
  const theme = useTheme();
  const isSize = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return isSize;
}
export function isDesktop() {
  const theme = useTheme();
  const isSize = useMediaQuery(theme.breakpoints.up("lg"));
  return isSize;
}

export function isToFixed(value: number, fixed: number = 2) {
  return value.toFixed(fixed);
}

// Get From Shared API - START
export function getRootObject(data: any, key?: string) {
  if (key) return data?.Shared?.Data?.Content[key];
  return data?.Shared?.Data?.Content;
}

export function getAPIs(data: any) {
  return getRootObject(data, "Private")?.APIs;
}

export function getAllowedValues(data: any, key: string, target: string) {
  return getRootObject(data, "AllowedValues")[key][target];
}

export function getGroupCount(data: any) {
  return getRootObject(data, "User")?.private?.sellpointID[0]?.public?.group;
}

export function getString(data: any, key: string) {
  const dictionary = getRootObject(data, "Dictionary");
  const userLanguage = getRootObject(data, "User")?.Public?.Language || "de";
  const getObjectByKeys = dictionary.find((item: any) => item.key === key);
  if (!getObjectByKeys) return "(*) " + key;
  return getObjectByKeys.names[userLanguage];
}
// Get From Shared API - END

export function getExtraHeaders(populateCategoriesIDs: boolean, populateTagsIDs: boolean, populateFilesIDs: boolean, paginationcurrent?: any, paginationlimit?: any) {
  return {
    ...(populateCategoriesIDs && { populateCategoriesIDs: populateCategoriesIDs }),
    ...(populateTagsIDs && { populateTagsIDs: populateTagsIDs }),
    ...(populateFilesIDs && { populateFilesIDs: populateFilesIDs }),
    ...(paginationcurrent && { paginationcurrent: paginationcurrent }),
    ...(paginationlimit && { paginationlimit: paginationlimit }),
  };
}

export function isLoggedIn(data: any): boolean {
  return getRootObject(data, "User") !== undefined;
}

export function isRole(data: any, role: Array<string>): boolean {
  if (role.includes("PUBLIC")) return true;
  if (!isLoggedIn(data)) return false;
  const userRoles = getRootObject(data, "User")?.private?.roles;
  return role.some((r) => userRoles?.includes(r));
}

export function getAlertOptions(open?: boolean, title?: string, message?: any, severity?: string) {
  if (open === undefined) open = false;
  if (title === undefined) title = "";
  if (message === undefined) message = "";
  if (severity === undefined) severity = "";
  return { open: open, title: title, message: message, severity: severity };
}

export function convertToBase64(files: any) {
  if (!files || files.length === 0) return [];
  let base64: (string | ArrayBuffer | null)[] = [];
  for (let i = 0; i < files.length; i++) {
    let reader = new FileReader();
    reader.readAsDataURL(files[i]);
    reader.onload = () => {
      base64.push((reader.result as any).toString());
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  }
  return base64;
}

export function getDynamicProperty(data: any, key: string) {
  const properties = key.split("[").map((x: string) => x.replace(/[\]"]+/g, ""));
  let finalName = data;
  for (const property of properties) {
    if (finalName && finalName.hasOwnProperty(property)) {
      finalName = finalName[property];
    } else {
      return "";
    }
  }
  return finalName;
}

export function getRightDefaultValue(item: any, managedItem: any, method: string) {
  if (method === "POST") {
    switch (item.element) {
      case "CCB":
        return false;
      case "CSL":
        return [];
      case "CHF":
        return item.extra.defaultValue;
      default:
        return "";
    }
  } else {
    switch (item.element) {
      case "CSL":
        return getDynamicProperty(managedItem, item.value);
      default:
        return getDynamicProperty(managedItem, item.value);
    }
  }
}

export function getObjectByKey(data: any, key: string) {
  const object = data.find((item: any) => item.key === key);
  return object;
}

export function getRightLabel(item: any) {
  if (item?.names) return item.names.de;
  return item;
}

export function getRightKey(item: any) {
  if (item?._id) return item._id;
  return item;
}

export function replaceIDwithLabel(data: any, selectedItem: any) {
  const item = data.filter((x: any) => x._id === selectedItem);
  return item.length === 0 ? selectedItem : getRightLabel(item[0]);
}

export function getFormatedDate(date: string) {
  return moment(date).format("DD.MM.YYYY HH:mm:ss");
}

export function isAllowed(userData: any, menuItem: any, source: string) {
  if (source === "Navigation" && menuItem.visibleMenu === true) return isRole(userData, menuItem.roles);
  if (source === "App") return isRole(userData, menuItem.roles);
  return false;
}

export function isOrderNotValid(data: any) {
  if (data.Data.Content.length === 0) return true;
  return false;
}

export function delayedExecution(callback: any, ...args: any) {
  setTimeout(() => {
    callback(...args);
  }, defaultDelay);
}

export function getIcon(icon: string) {
  switch (icon) {
    case "FAVORITE":
      return faStar;
    default:
      return faImages;
  }
}
