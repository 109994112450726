import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardMedia, Typography } from "@mui/material";
import { backEndUrl, defaultImageURL } from "../../hooks/constants";
import { getIcon } from "../../hooks/functions";

export const CustomImageItem = (props: any) => {
  let { item, height } = props;
  item.icon = getIcon(item.icon);
  if (item?.filesIDs?.length === 0 || item?.filesIDs[0]?.url === "")
    return (
      <Typography color={"primary"} sx={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={item.icon} size="lg" />
      </Typography>
    );
  let image = item?.filesIDs[0]?.url;
  const finalURL = image ? backEndUrl + "/" + image : defaultImageURL;
  return <CardMedia component="img" height={height} image={finalURL} />;
};
