import { faCircleDot, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { globalContext } from "../../GlobalContext";
import { CustomCalculatorInputValue, calcNumbers } from "../../hooks/constants";
import { getRootObject } from "../../hooks/functions";
import { CustomButton } from "./CustomButton";

// OnChange is Optional
export const CustomCalculator = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData, globalProps, setGlobalProps } = globalContext();
  const { spacing } = getRootObject(sharedData, "Public")?.Layout?.Values;
  // Calling Global API DATA - END

  let { onChange } = props;
  const [inputValue, setInputValue] = useState<string>(CustomCalculatorInputValue);

  useEffect(() => {
    setGlobalProps({
      ...globalProps,
      CustomCalculator: {
        inputValue,
      },
    });
    onChange && onChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (globalProps?.CustomCalculator?.inputValue === CustomCalculatorInputValue) {
      setInputValue(CustomCalculatorInputValue);
    }
  }, [globalProps?.CustomCalculator?.inputValue]);

  const handleButtonClick = (value: string) => {
    if (value === "." && inputValue.includes(".")) return;
    if (inputValue === CustomCalculatorInputValue && value === ".") {
      setInputValue(CustomCalculatorInputValue + value);
      return;
    }
    if (inputValue === CustomCalculatorInputValue) {
      setInputValue(value);
      return;
    }
    setInputValue(inputValue + value);
  };

  const handleBackspace = () => {
    if (inputValue.length === 1) {
      setInputValue(CustomCalculatorInputValue);
      return;
    }
    setInputValue(inputValue.slice(0, -1));
  };

  return (
    <Card elevation={0}>
      <Grid container textAlign={"center"} spacing={spacing.medium}>
        {calcNumbers.map(
          (item) =>
            item.label !== 0 && (
              <Grid item xs={4} key={item.label}>
                <CustomButton label={item.label.toString()} sx={{ fontWeight: "bold" }} onClick={() => handleButtonClick(item.label.toString())} fullWidth />
              </Grid>
            )
        )}
        <Grid item xs={4}>
          <CustomButton icon={faDeleteLeft} onClick={handleBackspace} fullWidth />
        </Grid>
        {calcNumbers.map(
          (item) =>
            item.label === 0 && (
              <Grid item xs={4} key={item.label}>
                <CustomButton label={item.label.toString()} sx={{ fontWeight: "bold" }} onClick={() => handleButtonClick(item.label.toString())} fullWidth />
              </Grid>
            )
        )}
        <Grid item xs={4}>
          <CustomButton icon={faCircleDot} onClick={() => handleButtonClick(".")} fullWidth />
        </Grid>
      </Grid>
    </Card>
  );
};
