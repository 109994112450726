import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { globalContext } from "../../GlobalContext";
import { getString } from "../../hooks/functions";
import { CustomSpaces } from "./CustomSpaces";

export const CustomTitle = (props: any) => {
  // Calling Global API's DATA - START
  const { sharedData } = globalContext();
  // Calling Global API DATA - END

  let { title, icon, variant } = props;

  return (
    <Box>
      <Typography variant={variant || "h5"} component={variant || "h5"} gutterBottom color={"primary"} sx={{ fontWeight: "500" }}>
        <Box display="flex" alignItems="center">
          {icon && <FontAwesomeIcon icon={icon} />}
          <CustomSpaces />
          {getString(sharedData, title)}
        </Box>
      </Typography>
    </Box>
  );
};
